import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import ArticleCard from '../ArticleCard';

import '../../styles/components/sections/blogSection.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

function BlogSection() {
    const [articles, setArticles] = useState([]);
    const [activeSlideId, setActiveSlideId] = useState(1);
    const [buttonsActive, setButtonsActive] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const slider = useRef(null);
    const sliderMobile = useRef(null);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/blog/articles`)
            .then(res => res.json())
            .then(
                (result) => {
                    setArticles(result);

                }
            )
    }, []);

    useEffect(() => {
        const debounceHandleResize = debounce(function handleResize() {
            setWindowWidth(window.innerWidth);
        }, 1000);

        window.addEventListener('resize', debounceHandleResize);

        return _ => {
            window.removeEventListener('resize', debounceHandleResize);
        }
    });

    useEffect(() => {
        if (windowWidth <= 576) {
            setButtonsActive(true);
        } else if (windowWidth > 576 && windowWidth < 1440 && articles.length > 2) {
            setButtonsActive(true);
        } else if (windowWidth >= 1440 && windowWidth < 1999 && articles.length > 3) {
            setButtonsActive(true);
        } else if (windowWidth >= 1999 && articles.length > 4) {
            setButtonsActive(true);
        } else {
            setButtonsActive(false);
        }
    }, [windowWidth, articles.length]);

    const sliderSettings = {
        arrows: false,
        autoplay: buttonsActive,
        autoplaySpeed: 3500,
        dots: false,
        draggable: true,
        easing: 'ease',
        infinite: buttonsActive,
        slidesToScroll: 1,
        slidesToShow: 4,
        swipe: true,
        responsive: [
            {
                breakpoint: 1999,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1439,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    // dots: false,
                    // dotsClass: 'slider-dots',
                    // customPaging: i => (
                    //     <div className="dot"></div>
                    // )
                },
            },
            {
                breakpoint: 556,
                settings: {
                    slidesToShow: 1,
                    // dots: false,
                    // dotsClass: 'slider-dots',
                    // customPaging: i => (
                    //     <div className="dot"></div>
                    // )
                }
            }
        ],
        afterChange: current => setActiveSlideId(current + 1)
    };

    var dotsGroup = document.getElementsByClassName('slider-dots');
    for (let index = 0; index < dotsGroup.length; index++) {
        var dots = dotsGroup[index].getElementsByTagName('button');
        for (let j = 0; j < dots.length; j++) {
            dots[j].innerHTML = '';
        }
    }

    return (
        <section id="blog">
            <div className="d-none d-lg-flex flex-row justify-content-between row">
                <div className="col1 col-md-2 d-flex flex-column justify-content-between align-items-center">
                    <h3 className="white">Notre actualité</h3>
                    <div id="nav_arrows">
                        <button type="button" role="presentation" className={"prev" + (!buttonsActive ? " inactive" : "")} onClick={() => buttonsActive ? slider.current.slickPrev() : undefined}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <button type="button" role="presentation" className={"next" + (!buttonsActive ? " inactive" : "")} onClick={() => buttonsActive ? slider.current.slickNext() : undefined}>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </div>
                </div>
                <div className="col2 col-md-6">
                    {!articles.length ? (
                        <div className="col-lg-12 bl_full_ln text-center">
                            Aucune actu pour le moment.
                        </div>
                    ) : (
                        <Slider ref={c => (slider.current = c)} {...sliderSettings}>
                            {articles.map(article => (
                                <ArticleCard key={article.id} article={article} />
                            ))}
                        </Slider>
                    )
                    }
                </div>
                <div className="col3 col-md-2 p-0 d-flex flex-row justify-content-center align-items-center">
                    {articles.length ? activeSlideId : 0}
                    <span className="separator">|</span>
                    {articles ? articles.length : null}
                </div>
            </div>
            <div className="d-flex d-lg-none flex-column justify-content-between align-items-center row">
                <div className="col1 col-xs-12 d-flex justify-content-center">
                    <h3 className="white">Notre actualité</h3>
                </div>
                <div className="col2 container col-xs-12">
                    {!articles.length ? (
                        <div className="col-lg-12 bl_full_ln text-center">
                            Aucune actu pour le moment.
                        </div>
                    ) : (
                        <Slider ref={c => (sliderMobile.current = c)} {...sliderSettings}>
                            {articles.map(article => (
                                <ArticleCard key={article.id} article={article} />
                            ))}
                        </Slider>
                    )
                    }
                </div>
                <div className="col3 col-xs-12 p-0 d-flex flex-row justify-content-center align-items-center">
                    {articles.length ? activeSlideId : 0}
                    <span className="separator">|</span>
                    {articles ? articles.length : null}
                </div>
                <div className="col-xs-12 w-100">
                    <div id="nav_arrows" className="d-flex flex-row w-100">
                        <button type="button" role="presentation" className={"prev" + (!buttonsActive ? " inactive" : "")} onClick={() => buttonsActive ? sliderMobile.current.slickPrev() : undefined}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                        <button type="button" role="presentation" className={"next" + (!buttonsActive ? " inactive" : "")} onClick={() => buttonsActive ? sliderMobile.current.slickNext() : undefined}>
                            <FontAwesomeIcon icon={faArrowRight} />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default BlogSection;