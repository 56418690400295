import { faEnvelope, faMapMarkerAlt, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ContactForm from '../form/ContactForm';

export default class ContactSection extends React.Component {
    render() {
        return (
            <section id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 cnt_head align-self-center">
                            <span className="sub_head">Parlons de votre projet !</span>
                            <h2>Nous Contacter</h2>
                            <p className="h_body">Nous serions ravis de parler de vive voix de votre projet, de vos objectifs et de vos problématiques. </p>
                            <div className="cntc_info d-flex flex-row flex-wrap">
                                <div className="cntc_info_e">
                                    <div className="cntc_info_head"><span><FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />&nbsp;&nbsp;Adresse</span><br /></div>
                                    <span className="cntc_info_b">Lyon</span>
                                </div>
                                <div className="cntc_info_e">
                                    <div className="cntc_info_head"><span><FontAwesomeIcon icon={faPhoneAlt} className="icon" />&nbsp;&nbsp;Téléphone</span><br /></div>
                                    <a className="cntc_info_b" href={process.env.REACT_APP_TELTO_LINK}>{process.env.REACT_APP_PHONE_NUMBER}</a>
                                </div>
                                <div className="cntc_info_e">
                                    <div className="cntc_info_head"><span><FontAwesomeIcon icon={faEnvelope} className="icon" />&nbsp;&nbsp;Mail</span><br /></div>
                                    <a className="cntc_info_b" href={"mailto:" + process.env.REACT_APP_CONTACT_EMAIL}>{process.env.REACT_APP_CONTACT_EMAIL}</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 cntc_form align-self-center">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </section>
        )
    };
}