import React from 'react';
import { Helmet } from 'react-helmet';

import CoverImageSection from '../../components/sections/CoverImageSection';
import coverImage from '../../assets/images/vinicius-amnx-amano-f9oQZOk9vnk-unsplash.jpg';
import Vmv from '../Homepage/sections/Vmv';
import ContactFormSection from './sections/ContactFormSection';
import BlogSection from '../../components/sections/BlogSection';

import '../../styles/pages/Contact/contactPage.scss';

export default class Contact extends React.Component {
    render() {
        return (
            <div id="contact-page">
                <Helmet>
                    <title>Entrons en contact - Nous contacter - Lotaky</title>
                    <meta name="description" content="Agence de développement web à Lyon : N'hésitez pas à contacter l'équipe Lotaky afin d'échanger sur votre besoin." />
                </Helmet>
                <CoverImageSection
                    title="Contact"
                    bgImage={coverImage}
                    breadcrumbOrigin="Accueil"
                    breadcrumbPath="/"
                />
                <Vmv />
                <ContactFormSection />
                <BlogSection />
            </div>
        );
    }
}