import React from 'react';
import Button from './Button';
import bgImage from '../assets/images/p6.jpg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';

import '../styles/components/projectCard.scss';

export default class ProjectCard extends React.Component {
    render() {
        const {
            name,
            customer,
            thumbnail,
            slug,
            duration,
            difficulty,
            technology,
        } = this.props.project;

        const {
            buttonText = "En savoir plus",
        } = this.props;

        var stars;
        switch (difficulty) {
            case 0:
                stars = [0, 0, 0, 0];
                break;
            case 1:
                stars = [1, 0, 0, 0];
                break;
            case 2:
                stars = [1, 1, 0, 0];
                break;
            case 3:
                stars = [1, 1, 1, 0];
                break;
            case 4:
                stars = [1, 1, 1, 1];
                break;
            default:
                stars = [0, 0, 0, 0];
                break;
        }

        return (
            <div className="project-card" style={{
                background: 'linear-gradient(0deg, rgba(0,0,0,0.9) 13%, rgba(22,22,22,0.8) 61%, rgba(90,90,90,0.7) 100%), url(' + (thumbnail != null ? process.env.REACT_APP_BACKEND_URL + thumbnail.file : bgImage) + ') center center no-repeat',
                backgroundSize: 'cover'
            }}>
                <div className="pr_obj">
                    <div className="pr_main">
                        <div className="pr_head">
                            <div className="pr_title">{name}</div>
                            <div className="pr_subtitle">{customer}</div>
                        </div>
                        <div className="pr_content">
                            <ul>
                                <li>{technology}</li>
                                <li><FontAwesomeIcon icon={faCalendar} />&nbsp;{duration}&nbsp;jours de réalisation</li>
                                <li>
                                    Niveau de difficulté :
                                    <div className="pr_difficulty">
                                        {stars.map((element, i) => {
                                            if (element === 1) {
                                                return (<div key={i} className="star full"></div>);
                                            } else {
                                                return (<div key={i} className="star"></div>);
                                            }
                                        })}
                                    </div>
                                </li>
                            </ul>
                            <div className="pr_lnk">
                                <Button text={buttonText} link={`/projects/${slug}`} darkBG externalLink />
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        );
    }
}