import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ProjectsCategorySection from '../../Projects/ProjectsPage/sections/ProjectsCategorySection';

export default class Projects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            projects: []
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/projects`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        projects: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { projects } = this.state;

        return (
            <section id="projects">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 cnt_head">
                            <span className="sub_head">Les projets des entreprises qui nous ont fait confiance</span>
                            <h2>Nos réalisations</h2>
                            <p className="h_body">Nous nous assurons de fournir le meilleur service aux entreprises qui nous confient la création de leurs outils digitaux. Découvrez notre portfolio de réalisations.</p>
                        </div>
                    </div>
                </div>
                <div className="container-lg pr_cnt">
                    {!projects.length ? (
                        <div className="col-lg-12 bl_full_ln text-center">
                            Aucune réalisation pour le moment.
                        </div>
                    ) : (
                        <ProjectsCategorySection
                            bgColor="white"
                            category={{ name: "Nos derniers projets", projects: projects }}
                            h2={true}
                        />
                    )}
                </div>
            </section>
        );
    }
}
