import React from "react";
import Button from "./Button";

import '../styles/components/notFound.scss';

export default class NotFound extends React.Component {
    render() {
        return (
            <section id="not_found" className="d-flex flex-column justify-content-center align-items-center">
                <h1>Not found !</h1>
                <p>La page que vous cherchez est introuvable.</p>
                <Button
                    text="Retourner à l'Accueil"
                    link="/"
                    externalLink
                />
            </section>
        );
    }
}