import React from 'react';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../../../../styles/pages/Projects/projectsCategorySection.scss'
import ArticleCard from '../../../../components/ArticleCard';

export default class BlogMobileCategorySections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeSlideId: 1,
        };
    }

    _next = () => {
        this.sliderMobile.slickNext();
    }

    _previous = () => {
        this.sliderMobile.slickPrev();
    }

    afterChangeHandler(currentSlide) {
        this.props.updateInitialSlide(currentSlide);
    }

    render() {
        const {
            id,
            name = "Default",
            articles = []
        } = this.props.category;
        const {
            bgColor,
        } = this.props;

        var color = '';
        var darkElements = false;
        switch (bgColor) {
            case 'primary':
                color = 'primary-background';
                break;
            case 'white':
                color = 'white-background';
                darkElements = true;
                break;
            default:
                color = 'secondary-background';
                break;
        }

        const sliderSettings = {
            infinite: articles && articles.length > 2 ? true : false,
            swipe: true,
            slidesToScroll: 1,
            slidesToShow: 2,
            autoplaySpeed: 5500,
            autoplay: true,
            arrows: false,
            draggable: true,
            easing: 'ease',
            centerMode: true,
            centerPadding: "-5px",
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        centerPadding: "40px",
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false,
                    },
                },
            ],
            afterChange: current => this.setState({ activeSlideId: current + 1 })
        };

        return (
            <section id={id} className={"projects-category-section " + color}>
                {/* <div className="d-none d-lg-flex flex-row justify-content-between row">
                    <div className="col1 col-md-2 d-flex flex-column justify-content-between align-items-center">
                        <h3 className={bgColor != null ? (darkElements ? "" : "white") : null}>{name}</h3>
                        <div id="nav_arrows" className={bgColor !== null ? (darkElements ? "opacity" : "") : null}>
                            <button type="button" role="presentation" className="prev" onClick={this._previous}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                            <button type="button" role="presentation" className="next" onClick={this._next}>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        </div>
                    </div>
                    <div className="col2 container col-md-6">
                        <Slider ref={c => (this.slider = c)} {...sliderSettings}>
                            {articles != null ?
                                articles.map(article => (
                                    <ArticleCard article={article} bgImage={article.thumbnail} />
                                )) : null
                            }
                        </Slider>
                    </div>
                    <div className={(bgColor != null ? (darkElements ? "dark-mode" : "white") : "") + " col3 col-md-2 p-0 d-flex flex-row justify-content-start align-items-center"}>
                        {this.state.activeSlideId}
                        <span className="separator">|</span>
                        {articles ? articles.length : null}
                    </div>
                </div> */}
                <div className="d-flex d-lg-none flex-column justify-content-between align-items-center row">
                    <div className="col1 col-xs-12 d-flex flex-column justify-content-between align-items-center">
                        <h3 className={bgColor != null ? (darkElements ? "" : "white") : null}>{name}</h3>
                    </div>
                    <div className="col2 col-xs-12">
                        <Slider ref={c => (this.sliderMobile = c)} {...sliderSettings}>
                            {articles != null ?
                                articles.map(article => (
                                    <ArticleCard key={article.id} article={article} bgImage={article.thumbnail} />
                                )) : null
                            }
                        </Slider>
                    </div>
                    <div className={(bgColor != null ? (darkElements ? "dark-mode" : "white") : "") + " col3 col-xs-12 col-md-2 p-0 d-flex flex-row justify-content-center align-items-center"}>
                        {this.state.activeSlideId}
                        <span className="separator">|</span>
                        {articles !== [] ? articles.length : null}
                    </div>
                    <div className="col-xs-12 w-100">
                        <div id="nav_arrows" className={bgColor !== null ? (darkElements ? "opacity" : "") : null}>
                            <button type="button" role="presentation" className="prev" onClick={this._previous}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                            <button type="button" role="presentation" className="next" onClick={this._next}>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}