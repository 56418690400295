import React from 'react';
import thomasm from '../../../assets/team/thomasm.jpg';
import bastien from '../../../assets/team/bastien.png';
import thomasb from '../../../assets/team/thomasb.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const TeamCard = ({ image, imageAlt, name, desc, linkedinLink }) => (
    <div className="col-lg-4 col-md-6 col-sm-6 team_crd">
        <div className="card">
            <div className="team_img_cnt">
                <img src={image} className="img-fluid rounded-circle mx-auto mb-3" height="400" width="400" alt={imageAlt} />
                <div className="m_sci">
                    <div className="d-flex justify-content-around align-items-center h-100">
                        <a target="_blank" href={linkedinLink} rel="noreferrer" className="p-2">
                            <FontAwesomeIcon icon={faLinkedinIn} className="icon" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="m_name text-center">{name}</div>
            <div className="m_dsc text-center">{desc}</div>
        </div>
    </div>
);

export default class Team extends React.Component {
    render() {
        return (
            <section id="team">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 cnt_head">
                            <span className="sub_head">Des spécialistes derrière vos projets</span>
                            <h2>Rencontrez notre équipe</h2>
                            <p className="h_body">Chez Lotaky nous avons une équipe polyvalente et spécialisée afin de vous fournir vos projets répondant le plus à vos attentes.</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row team_cnt">
                        <TeamCard image={thomasm} imageAlt="Thomas MARQUES" linkedinLink="https://www.linkedin.com/in/thomas-mrqs/" name="Thomas Marques" desc="CoFondateur" />
                        <TeamCard image={bastien} imageAlt="Bastien GUILLET" linkedinLink="https://www.linkedin.com/in/bastien-guillet/" name="Bastien Guillet" desc="CoFondateur" />
                        <TeamCard image={thomasb} imageAlt="Thomas BOLLE" linkedinLink="https://www.linkedin.com/in/thomas-bolle/" name="Thomas Bolle" desc="CoFondateur" />
                    </div>
                </div>
            </section>
        )
    };
}