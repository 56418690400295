import React from 'react';
import CoverImageSection from '../../../components/sections/CoverImageSection';

import coverImage from '../../../assets/images/sandro-katalina-k1bO_VTiZSs-unsplash.jpg';
import ThirdBlock from '../../../components/ThirdBlock';

import TextWithTitle from '../../../components/sections/TextWithTitleSection';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ServicesBlocks from '../../../components/ServicesBlocks';
import BlogSection from '../../../components/sections/BlogSection';

import '../../../styles/pages/Services/servicesPage.scss';
import { Helmet } from 'react-helmet';

export default class ServicesPage extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Agence développement web - Nos services - Lotaky</title>
                    <meta name="description" content="L’agence experte en développement web : Lotaky vous accompagne dans votre projet digital de sa conception à sa validation finale." />
                </Helmet>
                <CoverImageSection
                    title="Nos services"
                    bgImage={coverImage}
                    breadcrumbOrigin="Accueil"
                    breadcrumbPath="/"
                />
                <section id="section-1" className="container">
                    <div className="row ser_cnt">
                        <div className="col-lg-4 col-md-12 col-xs-12 serv">
                            <ThirdBlock
                                title="Développement web"
                                text="Créons ensemble votre projet web : Site vitrine, Site ecommerce, Outils web, Front-end, Back-end, etc."
                            />
                        </div>
                        <div className="col-lg-4 col-md-12 col-xs-12 serv">
                            <ThirdBlock title="Développement Mobile" text="Donner vie à votre application mobile iOS et Android" />
                        </div>
                        <div className="col-lg-4 col-md-12 col-xs-12 serv">
                            <ThirdBlock title="Consulting" text="Béneficier des meilleurs conseils de la part de nos experts en Développement web, Développement mobile et SEO" />
                        </div>
                    </div>
                </section >
                <TextWithTitle
                    titleWithTag={(<h2>Vous êtes notre obsession</h2>)}
                    text="Chez Lotaky, nous accompagnons nos clients tout au long de leur projet. De la conception aux correctifs des solutions développer, vous ferez partie intégrante au développement de votre projet."
                    buttonText="Nous contacter"
                    buttonIcon={faAngleRight}
                    buttonLink="/contact"
                />
                <TextWithTitle
                    titleWithTag={(<h2>Une équipe de développeur 3.0</h2>)}
                    text="Notre équipe de développeurs est experte dans les technologies open source telle que Symfony, React, Prestashop, Magento, WordPress, etc. Peu importe la taille de votre entreprise, nous adaptons la gestion de votre projet en fonction du besoin de votre entreprise."
                />
                <div className="container">
                    <ServicesBlocks />
                </div>
                <BlogSection />
            </div >
        );
    }
}