import React from 'react';
import ContactFormLong from '../../../components/form/ContactFormLong';

import '../../../styles/pages/Contact/contactFormSection.scss';

export default class ContactFormSection extends React.Component {
    render() {
        return (
            <section id="contact-form-section" className="container">
                <div className="row d-flex">
                    <div className="card">
                        <span>Contactez-nous</span>
                        <h2>Envie de démarrer un projet ensemble ?</h2>
                        <ContactFormLong />
                    </div>
                </div>
            </section>
        );
    }
}