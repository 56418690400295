import { faCalendarAlt, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import '../../../../styles/pages/Projects/sidebar.scss';

export default class Sidebar extends React.Component {
    render() {
        const {
            customer,
            startDate,
            website,
            opinionCustomerName,
            opinionCustomerRating,
            opinionCustomerContent,
            satisfactionRate,
            difficulty,
            coffeeConsumption,
            duration
        } = this.props.project;

        var stars = [];

        if (opinionCustomerRating >= 5) {
            for (let i = 0; i < 5; i++) {
                stars.push(<div className="star full"></div>);
            }
        } else {
            for (let j = 0; j < opinionCustomerRating; j++) {
                stars.push(<div className="star full"></div>);
            }
            for (let k = 0; k < 5 - opinionCustomerRating; k++) {
                stars.push(<div className="star"></div>);
            }
        }

        const difficulties = {
            1: "Easy",
            2: "Medium",
            3: "Difficult",
            4: "Hard",
        }

        var cups = [];
        var maxCups;
        coffeeConsumption > 36 ? maxCups = 36 : maxCups = coffeeConsumption;
        for (let l = 0; l < maxCups; l++) {
            cups.push(<FontAwesomeIcon icon={faCoffee} />)
        }

        var calendars = [];
        var maxCalendars;
        parseInt(duration) > 36 ? maxCalendars = 36 : maxCalendars = parseInt(duration);
        for (let l = 0; l < maxCalendars; l++) {
            calendars.push(<FontAwesomeIcon icon={faCalendarAlt} />)
        }


        return (
            <div className="pd_sidebar">
                <div className="gr" id="gr-1">
                    <h3>Carte projet</h3>
                    <ul className="fa-ul">
                        <li>
                            <span className="pd_name">Client</span>
                            <br />
                            <span className="pd_value">{customer}</span>
                        </li>
                        <li>
                            <span className="pd_name">Date</span>
                            <br />
                            <span className="pd_value">{startDate}</span>
                        </li>
                        {/* <li><span className="pd_name">Category</span><br><span className="pd_value">AI, VR, Machine Learning</span></li>
                    <li><span className="pd_name">Industry</span><br><span className="pd_value">Technology</span></li> */}
                        <li>
                            <span className="pd_name">Site-Web</span>
                            <br />
                            <a href={website} target="_blank" rel="noreferrer" className="pd_value">{website}</a>
                        </li>
                    </ul>
                </div>
                <div className="gr" id="gr-2">
                    <h4>Avis client</h4>
                    <span className="subhead">{opinionCustomerName}</span>
                    <h5>Note</h5>
                    <div className="pr_rating">
                        {stars ? stars.map((element, id) => <div key={id}>{element}</div>) : null}
                    </div>
                    <p>«&nbsp;{opinionCustomerContent}&nbsp;»</p>
                    <h4>Niveau de difficulté du projet</h4>
                    <div className="pr-difficulty">
                        {Object.keys(difficulties).map((element, id) => {
                            return parseInt(element) === difficulty ? (
                                <div key={id} className="level">
                                    <span className="level-name">{difficulties[element]}</span>
                                    <div className="line active"></div>
                                </div>
                            ) : (
                                <div key={id} className="level">
                                    <div className="line"></div>
                                </div>
                            );
                        }
                        )}
                    </div>
                </div>
                {satisfactionRate && (
                    <div className="gr" id="gr-3">
                        <h4>Taux de satisfaction</h4>
                        <span className="satisfaction">{satisfactionRate}&nbsp;%</span>
                    </div>
                )}
                {cups.length > 0 && (
                    <div className="gr" id="gr-4">
                        <h4>Consommation de café pour ce projet</h4>
                        <div className="pr-columns">
                            <span className="number">{coffeeConsumption}</span>
                            <div className="coffee-cups">
                                {cups.map((element, id) => <div key={id}>{element}</div>)}
                            </div>
                        </div>
                    </div>
                )}
                {calendars.length && (
                    <div className="gr" id="gr-5">
                        <h4>Temps de réalisation (jours)</h4>
                        <div className="pr-columns">
                            <span className="number">{duration}</span>
                            <div className="calendars">
                                {calendars.map((element, id) => <div key={id}>{element}</div>)}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}