import React, { useEffect, useRef, useState } from 'react';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectCard from '../../../../components/ProjectCard';
import Slider from 'react-slick';

import '../../../../styles/pages/Projects/projectsCategorySection.scss'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

function ProjectsCategorySection(props) {
    const { id, name, projects } = props.category;
    const { bgColor } = props;
    const h2 = props.h2 ?? false;

    const [activeSlideId, setActiveSlideId] = useState(1);
    const [buttonsActive, setButtonsActive] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const slider = useRef(null);
    const sliderMobile = useRef(null);

    useEffect(() => {
        const debounceHandleResize = debounce(function handleResize() {
            setWindowWidth(window.innerWidth);
        }, 1000);

        window.addEventListener('resize', debounceHandleResize);

        return _ => {
            window.removeEventListener('resize', debounceHandleResize);
        }
    });

    useEffect(() => {
        if (windowWidth <= 576) {
            setButtonsActive(true);
        } else if (windowWidth > 576 && windowWidth < 1440 && projects.length > 2) {
            setButtonsActive(true);
        } else if (windowWidth >= 1440 && projects.length > 3) {
            setButtonsActive(true);
        } else if (windowWidth >= 1999 && projects.length > 4) {
            setButtonsActive(true);
        } else {
            setButtonsActive(false);
        }
    }, [windowWidth, projects.length]);

    var color = '';
    var darkElements = false;
    switch (bgColor) {
        case 'primary':
            color = 'primary-background';
            break;
        case 'white':
            color = 'white-background';
            darkElements = true;
            break;
        default:
            color = 'secondary-background';
            break;
    }

    const sliderSettings = {
        infinite: buttonsActive,
        swipe: true,
        slidesToScroll: 1,
        slidesToShow: 4,
        autoplay: buttonsActive,
        autoplaySpeed: 5500,
        arrows: false,
        draggable: true,
        easing: 'ease',
        lazyLoad: true,
        responsive: [
            {
                breakpoint: 1999,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 1439,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: "0px"
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: "5px",
                },
            },
            {
                breakpoint: 599,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: "2px",
                },
            },
        ],
        afterChange: current => setActiveSlideId(current + 1)
    };

    return (
        <section id={id} className={"projects-category-section " + color}>
            {windowWidth > 991 ? (
                <div className="d-none d-lg-flex flex-row justify-content-between row">
                    <div className="col1 col-md-2 d-flex flex-column justify-content-between align-items-center">
                        {h2 ? (
                            <h3 className={bgColor != null ? (darkElements ? "" : "white") : null}>{name}</h3>
                        ) : (
                            <h2 className={bgColor != null ? (darkElements ? "" : "white") : null}>{name}</h2>
                        )}
                        <div id="nav_arrows" className={bgColor !== null ? (darkElements ? "opacity" : "") : null}>
                            <button type="button" role="presentation" className={"prev" + (!buttonsActive ? " inactive" : "")} onClick={() => buttonsActive ? slider.current.slickPrev() : undefined}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                            <button type="button" role="presentation" className={"next" + (!buttonsActive ? " inactive" : "")} onClick={() => buttonsActive ? slider.current.slickNext() : undefined}>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        </div>
                    </div>
                    <div className="col2 col-md-6">
                        <Slider ref={c => (slider.current = c)} {...sliderSettings}>
                            {projects != null ?
                                projects.map(project => (
                                    <ProjectCard key={project.id} project={project} />
                                ))
                                : null}
                        </Slider>
                    </div>
                    <div className={(bgColor != null ? (darkElements ? "dark-mode" : "white") : "") + " col3 col-md-2 p-0 d-flex flex-row justify-content-center align-items-center"}>
                        {activeSlideId}
                        <span className="separator">|</span>
                        {projects ? projects.length : null}
                    </div>
                </div>
            ) : (
                <div className="d-flex d-lg-none flex-column justify-content-between align-items-center row">
                    <div className="col1 col-xs-12 d-flex flex-column justify-content-between align-items-center">
                        {h2 ? (
                            <h3 className={bgColor != null ? (darkElements ? "" : "white") : null}>{name}</h3>
                        ) : (
                            <h2 className={bgColor != null ? (darkElements ? "" : "white") : null}>{name}</h2>
                        )}
                    </div>
                    <div className="col2 container col-xs-12">
                        <Slider ref={c => (sliderMobile.current = c)} {...sliderSettings}>
                            {projects != null ?
                                projects.map(project => (
                                    <ProjectCard key={project.id} project={project} />
                                ))
                                : null
                            }
                        </Slider>
                    </div>
                    <div className={(bgColor != null ? (darkElements ? "dark-mode" : "white") : "") + " col3 col-xs-12 col-md-2 p-0 d-flex flex-row justify-content-center align-items-center"}>
                        {activeSlideId}
                        <span className="separator">|</span>
                        {projects ? projects.length : null}
                    </div>
                    <div className="col-xs-12 w-100">
                        <div id="nav_arrows" className={bgColor !== null ? (darkElements ? "opacity" : "") : null}>
                            <button type="button" role="presentation" className={"prev" + (!buttonsActive ? " inactive" : "")} onClick={() => buttonsActive ? sliderMobile.current.slickPrev() : undefined}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                            <button type="button" role="presentation" className={"next" + (!buttonsActive ? " inactive" : "")} onClick={() => buttonsActive ? sliderMobile.current.slickNext() : undefined}>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </section>
    );
}

export default ProjectsCategorySection;