import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from './Button';

import '../styles/components/thirdBlock.scss';

const ListItem = ({ text }) => (
    <li>
        <span className="fa-li">
            <FontAwesomeIcon icon={faCheckSquare} />
        </span>{text}
    </li>
);

export default class ThirdBlock extends React.Component {
    render() {
        const {
            title,
            path = "",
            mainIcon,
            text = "",
            list = [],
        } = this.props;

        var content;
        if (list.length !== 0) {
            content = (
                <ul className="fa-ul ser_dsc card-text">
                    {list.map(item => {
                        if (item.title) return (<ListItem key={item.id} text={item.title} />);
                        return null;
                    })}
                </ul>);
        }
        if (text !== "") {
            content = (<p className="ser_dsc card-text"> {text} </p>);
        }

        var block = (
            <div className="ser_lnk card d-flex flex-column">
                <div className="content_part">
                    {mainIcon ? (
                        <span className="ser_ico">
                            <FontAwesomeIcon icon={mainIcon} className="icon" />
                        </span>
                    ) : null}
                    <span className="ser_ttl">{title}</span>
                    {content}
                </div>
                {path !== "" ? (
                    <div className="ser_lnk">
                        <Button
                            text="En savoir plus"
                            link={path}
                            formLink
                        />
                    </div>
                ) : null}
            </div>
        );

        return (
            <div className="card_container">
                {path !== "" ?
                    (<NavLink exact to={path}> {block} </NavLink>
                    ) : block
                } </div>
        );
    }
}