import React from 'react';
import CountUp from 'react-countup';
import VisibilitySensor from "react-visibility-sensor";

export default class Counter extends React.Component {
    render() {
        return (
            <section id="counter_up">
                <div className="container">
                    <VisibilitySensor partialVisibility offset={{ bottom: 10 }}>
                        {({ isVisible }) => (
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-xs-6 text-center count">
                                    <span className='counter'>
                                        {isVisible && <CountUp start={0} end={56896} duration={4} />}
                                        <span className="count_txt">+</span>
                                    </span>
                                    <span className="count_bdy">Lignes de codes</span>
                                </div>
                                <div className="col-lg-3 col-md-3 col-xs-6 text-center count">
                                    <span className='counter'>
                                        {isVisible && <CountUp start={0} end={3800} duration={2.25} />}
                                        <span className="count_txt">+</span>
                                    </span>
                                    <span className="count_bdy">Heures de travail</span>
                                </div>
                                <div className="col-lg-3 col-md-3 col-xs-6 text-center count">
                                    <span className='counter'>
                                        {isVisible && <CountUp start={0} end={26} duration={1.5} />}
                                        <span className="count_txt">+</span>
                                    </span>
                                    <span className="count_bdy">Projets accompli</span>
                                </div>
                                <div className="col-lg-3 col-md-3 col-xs-6 text-center count">
                                    <span className='counter'>
                                        {isVisible && <CountUp start={0} end={100} duration={2} />}
                                        <span className="count_txt">%</span>
                                    </span>
                                    <span className="count_bdy">Satisfait</span>
                                </div>
                            </div>
                        )}
                    </VisibilitySensor>
                </div>
            </section>
        );
    }
}