import React, { useEffect, useState } from 'react';
import { setTheme } from '../assets/utils/themes.js';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';

function ColorModeToggle() {
    const [togClass, setTogClass] = useState('dark');
    let theme = localStorage.getItem('theme');

    if (localStorage.getItem('theme') === 'theme-dark') {
        setTheme('theme-dark');
    } else {
        setTheme('theme-light');
    }

    const handleOnClick = () => {
        if (localStorage.getItem('theme') === 'theme-dark') {
            setTheme('theme-light');
            setTogClass('light')
        } else {
            setTheme('theme-dark');
            setTogClass('dark')
        }
    }

    useEffect(() => {
        if (localStorage.getItem('theme') === 'theme-dark') {
            setTogClass('dark')
        } else if (localStorage.getItem('theme') === 'theme-light') {
            setTogClass('light')
        }
    }, [theme])

    return (
        <div className="container--toggle">
            {
                togClass === "light" ?
                    <BootstrapSwitchButton
                        checked={false}
                        onlabel={<FontAwesomeIcon icon={faMoon} />}
                        offlabel={<FontAwesomeIcon icon={faSun} />}
                        onstyle="dark"
                        offstyle="light"
                        size="xs"
                        height={25.5938}
                        width={33.0625}
                        onChange={handleOnClick}
                    />
                    :
                    <BootstrapSwitchButton
                        checked={true}
                        onlabel={<FontAwesomeIcon icon={faMoon} />}
                        offlabel={<FontAwesomeIcon icon={faSun} />}
                        onstyle="dark"
                        offstyle="light"
                        size="xs"
                        height={25.5938}
                        width={33.0625}
                        onChange={handleOnClick}
                    />
            }
        </div>
    )
}

export default ColorModeToggle;