import React from 'react';

import ContactSection from '../../../components/sections/ContactSection';
import ProjectsCategorySection from './sections/ProjectsCategorySection';
import CoverColorSection from '../../../components/sections/CoverColorSection';
import { Helmet } from 'react-helmet';

export default class ProjectsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            categories: []
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/projects/categories`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        categories: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { categories } = this.state;

        return (
            <div id="projects-page" >
                <Helmet>
                    <title>Agence de développement web - Nos réalisations - Lotaky</title>
                    <meta name="description" content="Lotaky : Découvrez les projets de nos clients. Nous vous expliquons les raisons qui nous ont poussé à travailler ensemble." />
                </Helmet>
                <CoverColorSection title="Nos Réalisations" height="tall" noBreak categories={categories} />
                {!categories.length ? (
                    <div className="col-lg-12 bl_full_ln text-center">
                        Aucune réalisation pour le moment.
                    </div>
                ) : (
                    <div>
                        {categories.map(category => (
                            <ProjectsCategorySection key={category.id} category={category} />
                        ))}
                    </div>
                )
                }
                <ContactSection />
            </div>
        );
    }
}