import { faBookmark, faChartLine, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export default class Vmv extends React.Component {
    render() {
        return (
            <section id="vmv">
                <div className="container">
                    <div className="row row_vmv"  >
                        <div className="col-lg-4 vmv">
                            <div className="vmv_cnt">
                                <div className="p-2"><span className='ttl'><FontAwesomeIcon icon={faBookmark} className="icon" />&nbsp;&nbsp;Vision</span></div>
                                <div className="p-2">Pour nous le web est un indispensable pour votre entreprise. C'est pourquoi nous portons une grande attention à chaque étape de votre transition.</div>
                            </div>
                        </div>
                        <div className="col-lg-4 vmv">
                            <div className="vmv_cnt">
                                <div className="p-2"><span className='ttl'><FontAwesomeIcon icon={faChartLine} className="icon" />&nbsp;&nbsp;Missions</span></div>
                                <div className="p-2">Vous accompagner, via des solutions adaptées précisément à vos besoins. Connaître vos envies pour les retranscrire au travers de votre présence digitale.</div>
                            </div>
                        </div>
                        <div className="col-lg-4 vmv">
                            <div className="vmv_cnt">
                                <div className="p-2"><span className='ttl'><FontAwesomeIcon icon={faChartPie} className="icon" />&nbsp;&nbsp;Valeurs</span></div>
                                <div className="p-2">Expertise, Agilité et Solutions sont les mots d'ordres de Lotaky. Notre volonté est de dépasser vos objectifs.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}