import React from 'react';
import ThirdBlock from './ThirdBlock';

export default class ServicesBlocks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            services: []
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/services`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        services: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        const { services } = this.state;

        return (
            <div className="row ser_cnt">
                {services.map(service => (
                    <div key={service.id} className="col-lg-4 col-md-12 col-xs-12 serv">
                        <ThirdBlock title={service.title} mainIcon={service.icon} list={service.keyPoints} path={`/services/${service.slug}`} />
                    </div>
                ))}
            </div>
        );
    }
}