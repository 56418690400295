import React from 'react';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../assets/logo/logo_lotaky_clear.svg';
import ColorModeToggle from '../components/ColorModeToggle';

import '../styles/layouts/header.scss';

export default class Header extends React.Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg fixed-top scroll">
                <div className="container">
                    <a className="navbar-brand" href="/">
                        <img src={logo} height="40" alt="logo" />
                        &nbsp;LOTAKY
                    </a>

                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <FontAwesomeIcon icon={faBars} />
                    </button>

                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav ml-auto flex-column flex-lg-row align-items-center">
                            <li className="nav-item w-100 d-sm-flex justify-content-center">
                                <a className="nav-link" href="/">Accueil</a>
                            </li>
                            <li className="nav-item w-100 d-sm-flex justify-content-center">
                                <a className="nav-link" href="/services">Services</a>
                            </li>
                            <li className="nav-item w-100 d-sm-flex justify-content-center">
                                <a className="nav-link" href="/#introduction">Performances</a>
                            </li>
                            <li className="nav-item w-100 d-sm-flex justify-content-center">
                                <a className="nav-link" href="/#team">Équipe</a>
                            </li>
                            <li className="nav-item w-100 d-sm-flex justify-content-center">
                                <a className="nav-link" href="/projects">Réalisations</a>
                            </li>
                            <li className="nav-item w-100 d-sm-flex justify-content-center">
                                <a className="nav-link" href="/blog">Actualités</a>
                            </li>
                            <li className="nav-item w-100 d-sm-flex justify-content-center">
                                <a className="nav-link" href="/contact">Contact</a>
                            </li>
                            <li className="nav-item w-100 d-sm-flex justify-content-center pt-3 pt-lg-0">
                                <ColorModeToggle />
                            </li>
                        </ul>
                    </div>
                </div>
            </nav >
        );
    }
}