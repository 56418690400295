import React from 'react';
import CoverImageSection from '../../../components/sections/CoverImageSection';

import coverImage from '../../../assets/images/cover-2.png';
import TextWithTitleSection from '../../../components/sections/TextWithTitleSection';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import ThirdBlock from '../../../components/ThirdBlock';
import Button from '../../../components/Button';
import ImageWithTextBlockSection from '../../../components/sections/ImageWithTextBlockSection';
import Collapse from '../../../components/Collapse';
import BlogSection from '../../../components/sections/BlogSection';

import '../../../styles/pages/Services/singleService.scss';
import Helmet from 'react-helmet';
export default class SingleService extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            service: null
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/services/${this.props.match.params.slug}`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        service: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }
    render() {
        const { service } = this.state;

        return service && (
            <div id='single-service'>
                <Helmet>
                    <title>{service.metaTitle ? service.metaTitle : "Agence développement web - Nos services - Lotaky"}</title>
                    <meta name="description" content={service.metaDesc} />
                </Helmet>
                <CoverImageSection
                    title={service.title}
                    bgImage={coverImage}
                    breadcrumbOrigin="Accueil"
                    breadcrumbPath="/"
                />
                {service.subtitle && (
                    <TextWithTitleSection
                        titleWithTag={(<h2>{service.subtitle}</h2>)}
                        text={service.content}
                        buttonText="Nous contacter"
                        buttonIcon={faAngleRight}
                        buttonLink="/contact"
                    />
                )}
                {service.keyPointsTitle && (
                    <div>
                        <TextWithTitleSection
                            titleWithTag={(<h2>{service.keyPointsTitle}</h2>)}
                            text={service.keyPointsContent}
                        />
                        <section className="container">
                            <div className="row ser_cnt">
                                {service.keyPoints.map(keyPoint => keyPoint.title && (
                                    <div key={keyPoint.id} className="col-lg-4 col-md-12 col-xs-12 serv">
                                        <ThirdBlock title={keyPoint.title} mainIcon={keyPoint.icon} />
                                    </div>
                                ))}
                            </div>
                            <Button link="/contact" externalLink />
                        </section>
                    </div>
                )}
                {service.secondTitle && (
                    <ImageWithTextBlockSection
                        title={service.secondTitle}
                        text={service.secondContent}
                        buttonText="Nous contacter"
                        image={coverImage}
                        imageAlt="cover"
                    />
                )}
                {service.faqs && (
                    <div>
                        <TextWithTitleSection
                            titleWithTag={(<h2>{service.faqsTitle}</h2>)}
                            text={service.faqsContent}
                        />
                        <section className="container">
                            <div className="row">
                                {service.faqs.map(faq => (
                                    <div key={faq.id} className="col-sm-6">
                                        <Collapse
                                            title={faq.question}
                                            content={(
                                                <p>
                                                    {faq.answer}
                                                </p>
                                            )}
                                        />
                                    </div>
                                ))}
                            </div>
                        </section>
                    </div>
                )}
                <BlogSection />
            </div>
        );
    }
}