import React from 'react';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../components/Button';

export default class HomepageCover extends React.Component {
    render() {
        return (
            <section id="cover">
                <div className="container cvr_cnt">
                    <div className="row cvr_row">
                        <h1 className="sm col-sm-12 col-lg-12">Bienvenue chez Lotaky</h1>
                        <p className="cvr-title col-sm-12 col-lg-12">L'Agence de développement <br></br><span className="cvr-title-tp">Web et Mobile</span></p>
                        <div className="col-lg-7 cvr-ttl align-self-center">
                            <p className="cvr-body">
                                Les spécialistes de la création de site internet et application sur mesure.<br></br><strong>Notre mission :</strong> digitaliser vos besoins en respectant votre cahier des charges que vous soyez une marque, une PME, une ETI ou une grande entreprise. Nous travaillons de manière agile sur vos projets, de la conception à la réalisation.
                            </p>
                            <Button darkBG link="/contact" externalLink />
                        </div>
                    </div>
                    <div className="container row links_row">
                        <div className="col-md-12 d-flex p-2 justify-content-between cvr-ftr align-items-center flex-wrap">
                            <div className="p-2 ftr-pr d-flex flex-row justify-content-between">
                                <a href="#services">Services</a>&nbsp;&nbsp;&nbsp;
                                <a href="#team">Notre équipe</a>
                            </div>
                            <div className="p-2 ftr-sc d-flex flex-row justify-content-between">
                                <a href="https://www.linkedin.com/company/lotaky" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                <a href={"mailto:" + process.env.REACT_APP_CONTACT_EMAIL} className="ml-2"><FontAwesomeIcon icon={faEnvelope} /></a>
                                <a href="tel:+33699062551" className="ml-2"><FontAwesomeIcon icon={faPhone} /></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        );
    }
}