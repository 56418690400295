import React from 'react';
import Button from '../Button';

import '../../styles/components/sections/textWithTitleSection.scss';

export default class TextWithTitleSection extends React.Component {
    render() {
        const {
            titleWithTag,
            text,
            buttonText,
            buttonIcon = "true",
            buttonLink,
        } = this.props;
        return (
            <section className="textWithTitle container">
                <div className="title">
                    {titleWithTag}
                </div>
                <div dangerouslySetInnerHTML={{ __html: text }} />
                <div className="twt-button">
                    {buttonText ? (<Button text={buttonText} link={buttonLink} icon={buttonIcon} externalLink />) : null}
                </div>
            </section>
        )
    };
}