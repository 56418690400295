import React from 'react';
import Button from '../Button';
import { withRouter } from 'react-router-dom';

import '../../styles/components/form.scss';

class ContactFormLong extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: '', selectedType: '' };
        this.state = {
            name: null,
            email: null,
            phone: null,
            message: null,
            type: null,
            budget: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/contact`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.props.history.push('/');
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-group long-form">
                    <label>
                        Quel est le type de votre projet ?
                    </label>
                    <div className="radio-buttons d-flex flex-md-row flex-column">
                        <div className="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="type"
                                    value="site"
                                    checked={this.state.type === "site"}
                                    onChange={(event) => this.handleChange(event)}
                                    id={1}
                                />
                                Un site internet
                            </label>
                        </div>
                        <div className="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="type"
                                    value="application"
                                    checked={this.state.type === "application"}
                                    onChange={(event) => this.handleChange(event)}
                                    id={2}
                                />
                                Une application mobile
                            </label>
                        </div>
                        <div className="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="type"
                                    value="autres"
                                    checked={this.state.type === "autres"}
                                    onChange={(event) => this.handleChange(event)}
                                    id={3}
                                />
                                Autres
                            </label>
                        </div>
                    </div>
                </div>
                <div className="form-group long-form">
                    <label>
                        Quelle est votre adresse e-mail de contact ?
                    </label>
                    <input
                        type="email"
                        name="email"
                        value={this.state.value}
                        onChange={(event) => this.handleChange(event)}
                        placeholder="Entrez votre e-mail"
                        required
                    />
                </div>
                <div className="form-group long-form">
                    <label>
                        Quelle est votre numéro de contact ?
                    </label>
                    <input
                        type="text"
                        name="phone"
                        value={this.state.value}
                        onChange={(event) => this.handleChange(event)}
                        placeholder="Entrez votre numéro"
                        required
                    />
                </div>
                <div className="form-group long-form">
                    <label>
                        Parlez-nous de votre projet ?
                    </label>
                    <textarea
                        value={this.state.value}
                        name="message"
                        onChange={(event) => this.handleChange(event)}
                        placeholder="Entrez votre présentation de projet"
                        required
                    />
                </div>
                <div className="form-group long-form">
                    <label>
                        Quel est votre budget ?
                    </label>
                    <div className="radio-buttons d-flex flex-md-row flex-column">
                        <div className="column">
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        name="budget"
                                        value="-10k"
                                        checked={this.state.budget === "-10k"}
                                        onChange={(event) => this.handleChange(event)}
                                        id={1}
                                    />
                                    Moins de 10 000 €
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        name="budget"
                                        value="10k-49K"
                                        checked={this.state.budget === "10k-49K"}
                                        onChange={(event) => this.handleChange(event)}
                                        id={2}
                                    />
                                    10 000 € - 49 999 €
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        name="budget"
                                        value="50k-100k"
                                        checked={this.state.budget === "50k-100k"}
                                        onChange={(event) => this.handleChange(event)}
                                        id={3}
                                    />
                                    50 000 € - 100 000 €
                                </label>
                            </div>
                        </div>
                        <div className="column">
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        name="budget"
                                        value="+100k"
                                        checked={this.state.budget === "+100k"}
                                        onChange={(event) => this.handleChange(event)}
                                        id={1}
                                    />
                                    Plus de 100 000 €
                                </label>
                            </div>
                            <div className="radio">
                                <label>
                                    <input
                                        type="radio"
                                        name="budget"
                                        value="à déterminer"
                                        checked={this.state.budget === "à déterminer"}
                                        onChange={(event) => this.handleChange(event)}
                                        id={2}
                                    />
                                    Budget à déterminer
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-button">
                    <Button formButton text="Envoyer votre message" />
                </div>
            </form >
        );
    }
}

export default withRouter(ContactFormLong);