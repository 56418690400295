import React, { useEffect, useRef, useState } from 'react';

const Dot = React.forwardRef(({ category, callback, x, y }, ref) => {
    return (
        <div className="ctg-dot" ref={ref} onClick={() => callback(category)}>
            <div className="dot">
            </div>
            <span className="ctg-name" style={{ transform: `translate(calc(${x}px - 170px),${y}px )` }}>{category.name}</span>
        </div >
    )
});

const useMousePosition = () => {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const updateMousePosition = event => {
            setMousePosition({ x: event.clientX, y: event.clientY });
        }
        window.addEventListener("mousemove", updateMousePosition);
        return () => window.removeEventListener("mousemove", updateMousePosition)
    }, [])

    return mousePosition;
};

function CategoryDots(props) {
    const ref = useRef();
    const { x, y } = useMousePosition();
    return (
        <div className="ctg-dots">
            {props.categories.map(category => (
                <Dot key={category.id} x={x} y={y} category={category} callback={props.callback} ref={ref} />
            ))}
        </div>
    );
}

export default CategoryDots;