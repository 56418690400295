import React from 'react';

import CoverImageSection from '../../../components/sections/CoverImageSection';
import ContactSection from '../../../components/sections/ContactSection';
import coverImage from '../../../assets/images/cover.jpg';
import Sidebar from './sections/Sidebar';

import '../../../styles/pages/Projects/singleProject.scss';
import { Helmet } from 'react-helmet';

export default class SingleProject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            project: null
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/projects/${this.props.match.params.slug}`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        project: result
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                    window.location.href = "/404";
                }
            )
    }

    render() {
        const { project } = this.state;

        return project && (
            <div>
                <Helmet>
                    <title>{project.metaTitle ? project.metaTitle : project.name ? project.name : ""}</title>
                    <meta name="description" content={project.metaDesc ? project.metaDesc : project.description ? new DOMParser().parseFromString(project.description, 'text/html').querySelector('p').textContent.substring(0, 155) : ""} />
                </Helmet>
                <CoverImageSection title={project.name} bgImage={coverImage} />
                <section id="project_detail">
                    <div className="container">
                        <div className="row">
                            <div className="content col-lg-8 col-sm-12 col-md-8">
                                <div dangerouslySetInnerHTML={{ __html: project.description }} />
                            </div>
                            <div className="sidebar-container col-lg-4 col-sm-12 col-md-4">
                                <Sidebar project={project} />
                            </div>
                        </div>
                    </div>
                </section>
                <ContactSection />
            </div>
        );
    }
}