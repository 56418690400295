import React from 'react';

export default class ArticleCover extends React.Component {
    render() {
        const {
            title,
            publishDate,
        } = this.props;

        return (
            <section id="article_cover">
                <div className="container p-4">
                    <div className="row">
                        <div className="w-100">
                            <h1 className="sm d-flex justify-content-center justify-content-sm-start" id="article_title">{title}</h1>
                            <div className="d-flex flex-sm-row flex-column justify-content-between">
                                <h5 className="wrapper_nav d-flex justify-content-center justify-content-sm-start col-sm-12 col-md-6">
                                    <span>
                                        <a href="index.html" className="wrapper_nav_link">Blog</a>
                                    </span>&nbsp;&nbsp;/&nbsp;&nbsp;
                                    <span>{title}</span>
                                </h5>
                                <span className="d-flex justify-content-center justify-content-sm-end col-sm-12 col-md-6">
                                    <span className="article_datatype">Publié le</span>
                                    &nbsp;
                                    <span className="article_datavalue">{publishDate}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}