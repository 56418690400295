import React from 'react';
import logo from '../assets/logo/logo_lotaky_clear.svg';
import Button from '../components/Button';

import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../styles/main.scss';

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            services: null
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/services`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({ services: result });
                    console.log(result);
                }
            )
    }
    render() {
        return (
            <footer id="footer">
                <div className="container ftr_main">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 align-self-center">
                            <div className="ftr_brand">
                                <a href="/"><img src={logo} height="40" alt="logo" />&nbsp;LOTAKY</a>
                            </div>
                            <div className="ftr_intro">
                                <p>Fini de passer des heures à faire votre veille ! On vous informe sur les avancées technologiques et techniques de notre agence. On vous explique tout !</p>
                            </div>
                            <div className="ftr_nwsltr">
                                <label>Newsletter</label>
                                <form action="#0">
                                    <div className="form-group d-flex flex-wrap flex-row">
                                        <input type="email" className="p-2" placeholder="Entrer votre e-mail" />
                                        <Button text="Souscrire" formButton darkBG />
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-12 ftr_bdy align-self-center">
                            <div className="ftr_cnt ml-3 mr-3 d-flex flex-row justify-content-between flex-wrap">
                                <div className="ftr_clb">
                                    <span className="ftr_clb_t1">Vous avez une idée ?</span><br />
                                    <span className="ftr_clb_t2">Conceptualisons et réalisons ensemble vos projets !</span><br />
                                    <Button darkBG link="/contact" externalLink />
                                </div>
                                <div className="ftr_lnk d-flex flex-column">
                                    <div className="ftr_lnk_sc ml-auto">
                                        <a href="https://www.linkedin.com/company/lotaky" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedinIn} /></a>
                                        <a href={"mailto:" + process.env.REACT_APP_CONTACT_EMAIL} className="ml-2"><FontAwesomeIcon icon={faEnvelope} /></a>
                                        <a href={process.env.REACT_APP_TELTO_LINK} className="ml-2"><FontAwesomeIcon icon={faPhone} /></a>
                                    </div>
                                    <div className="ftr_lnk_cmp mt-4 ml-auto text-right d-flex flex-column">
                                        <span className="ftr_lnk_head">Nos Services</span>
                                        {this.state.services && this.state.services.map(service =>
                                            <span className="ftr_lnk_bdy"><a href={`/services/${service.slug}`} className="ser_lnk">{service.title}</a></span>
                                        )}
                                    </div>
                                    <div className="ftr_lnk_lpt mt-4 ml-auto">
                                        <span className="ftr_lnk_lpt_l"><a href="/">Privacy Policy</a></span><span className="ftr_lnk_lpt_dot">&nbsp;·&nbsp;</span><span className="ftr_lnk_lpt_l"><a href="/">Terms of use</a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="ftr_btm">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <span className='copyright'>© {new Date().getFullYear()} <a href="/">Lotaky</a>. Tous droits réservés.</span>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        );
    }
}