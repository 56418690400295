import React from 'react';
import '../styles/components/articleCard.scss';

export default class ArticleCard extends React.Component {
    render() {
        const {
            name,
            slug,
            thumbnail,
        } = this.props.article;

        const {
            bgImage,
        } = this.props;

        return (
            <a href={`/blog/articles/${slug}`} className="article-card d-flex" style={{
                background: 'linear-gradient(0deg, rgba(0,0,0,0.95) 13%, rgba(50,50,50,0.8) 100%), url(' + (thumbnail != null ? process.env.REACT_APP_BACKEND_URL + thumbnail.file : bgImage) + ') center center no-repeat',
                backgroundSize: 'cover'
            }}>
                <div className="article-card-content d-flex flex-column justify-content-between align-items-center">
                    <span className="title">{name}</span>
                    <div className="read_more ml-auto">
                        <span>En savoir plus {'>'}</span>&nbsp;&nbsp;__________
                    </div>
                </div>
            </a>
        );
    }
}