import React from 'react';
import { Helmet } from 'react-helmet';
import ContactSection from '../../components/sections/ContactSection';
import Counter from './sections/Counter';
import HomepageCover from './sections/HomepageCover';
import Introduction from './sections/Introduction';
import Projects from './sections/Projects';
import Services from './sections/Services';
import Team from './sections/Team';
import Vmv from './sections/Vmv';

export default class Homepage extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Agence développement web Lyon - Lotaky</title>
                    <meta name="description" content="Agence développement web à Lyon - Lotaky : Experts dans la création de site internet et application sur mesure, nous vous aidons à digitaliser vos besoins et votre marque." />
                </Helmet>
                <HomepageCover />
                <Vmv />
                <Services />
                <Introduction />
                <Counter />
                <Team />
                <Projects />
                <ContactSection />
            </div>
        );
    }
}