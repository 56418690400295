import React from 'react';
import ServicesBlocks from '../../../components/ServicesBlocks';

export default class Services extends React.Component {
    render() {
        return (
            <section id="services">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 cnt_head">
                            <span className="sub_head">Une complémentarité de nos spécialités</span>
                            <h2>Nos Services</h2>
                            <p className="h_body">Que ce soit pour la création ou la refonte de votre projet digital, nous vous aidons et accompagnons tout au long de la réalisation du projet.</p>
                        </div>
                    </div>
                    <ServicesBlocks />
                </div>
            </section>
        );
    }
}