import React, { Component } from 'react';
import Header from './Header.jsx';
import Footer from './Footer.jsx';


export default class Layout extends Component {
    render() {
        const { children } = this.props;
        return (
            <div className="App">
                <Header />
                {children}
                <Footer />
            </div>
        );
    }
}