import React from 'react';
import Button from '../Button';

import '../../styles/components/form.scss';

export default class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            message: "",
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSubmit(event) {
        event.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/contact`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.props.history.push('/');
                },
                (error) => {
                    console.log(error);
                }
            );
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                    <label>
                        Nom
                    </label>
                    <input
                        type="text"
                        value={this.state.name}
                        onChange={(event) => this.handleChange('name', event)}
                        placeholder="Entrez votre nom"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>
                        Email
                    </label>
                    <input
                        type="email"
                        value={this.state.email}
                        onChange={(event) => this.handleChange('email', event)}
                        placeholder="Entrez votre e-mail"
                        required
                    />
                </div>
                <div className="form-group">
                    <label>
                        Message
                    </label>
                    <textarea
                        value={this.state.message}
                        onChange={(event) => this.handleChange('message', event)}
                        placeholder="Entrez votre message"
                        required
                    />
                </div>
                <Button formButton text="Envoyer" />
            </form>
        );
    }
}