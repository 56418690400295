import React from 'react';
import Button from '../Button';

import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import '../../styles/components/thirdBlock.scss';
import '../../styles/components/sections/imageWithTextBlockSection.scss';

export default class ImageWithTextBlockSection extends React.Component {
    render() {
        const {
            title,
            text,
            image,
            imageAlt,
            buttonText,
            buttonIcon = faAngleRight,
            buttonLink = "/contact"
        } = this.props;

        return (
            <section className="image-with-text-block container" >
                {image ? (
                    <div className="row ml-auto mr-auto">
                        <div className="row ser_cnt blocks ml-auto mr-auto">
                            <div className="serv">
                                <div className="card d-flex flex-lg-row flex-column">
                                    <div className="imgtxt_block_content d-flex flex-column">
                                        <span className="ser_ttl">
                                            <h2>{title}</h2>
                                        </span>
                                        <div className="fa-ul ser_dsc card-text" dangerouslySetInnerHTML={{ __html: text }} />
                                        {buttonText ? (<div className="d-flex justify-content-center justify-content-lg-end">
                                            <Button text={buttonText} link={buttonLink} icon={buttonIcon} externalLink />
                                        </div>) : null}
                                    </div>
                                    <div className="imgtxt_block_img d-flex">
                                        <img src={image} className="img-fluid" alt={imageAlt} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </section>
        );
    }
}