import React from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../styles/components/thirdBlock.scss';
import '../styles/components/collapse.scss';

export default class Collapse extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            opened: false,
        };
    }

    _toggle = () => {
        this.setState({ opened: !this.state.opened });
    }

    render() {
        const {
            title,
            content,
        } = this.props;

        return (
            <div className="ser_cnt blocks" onClick={this._toggle}>
                <div className="col-xs-12 serv">
                    <div className="card drawer d-flex flex-column">
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <div className="d-flex flex-column">
                                <p className={this.state.opened ? "title toggle-title" : "title"}>{title}</p>
                            </div>
                            <span className="ser_ico d-flex align-items-center">
                                <FontAwesomeIcon icon={faAngleRight} className={this.state.opened ? "rotate-icon" : ""} />
                            </span>
                        </div>
                        {this.state.opened ? (
                            <div className="ser_dsc">
                                {content}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}