import React from 'react';
import { faChartBar, faGlobeAmericas, faServer, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import about from '../../../assets/homepage/about.jpg';

export default class Introduction extends React.Component {
    render() {
        return (
            <section id="introduction">
                <div className="container">
                    <div className="row justify-content-center icont">
                        <div className="col-lg-6 col-md-12 in_img align-self-center">
                            <img src={about} className="img-fluid" alt="in_img" />
                        </div>
                        <div className="col-lg-6 col-md-12 align-items-center">
                            <span className="sub_head">Nos performances</span>
                            <h2>Développez votre présence digital</h2>
                            <p className="h_body">L'ère du Digital est à portée de main ! Chaque année des milliers d’entreprises décident de se digitaliser. Pourquoi pas vous ?</p>
                            <div className="row">
                                <div className="icard col-md-6">
                                    <div className="card c1">
                                        <div className="d-flex flex-row align-items-center h-100 crd_cnt"  >
                                            <div className="p-2">
                                                <div className="card-title"><FontAwesomeIcon icon={faGlobeAmericas} className="icon" /></div>
                                            </div>
                                            <div className="crd_bdy">
                                                <p className="card-text">Augmentez votre visibilité en ligne</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="icard col-md-6">
                                    <div className="card c2">
                                        <div className="d-flex flex-row align-items-center h-100 crd_cnt"  >
                                            <div className="p-2">
                                                <div className="card-title"><FontAwesomeIcon icon={faServer} className="icon" /></div>
                                            </div>
                                            <div className="crd_bdy">
                                                <p className="card-text">Touchez 4,75 Milliards potentiels acheteurs sur internet</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="icard col-md-6">
                                    <div className="card c3">
                                        <div className="d-flex flex-row align-items-center h-100 crd_cnt"  >
                                            <div className="p-2">
                                                <div className="card-title"><FontAwesomeIcon icon={faShieldAlt} className="icon" /></div>
                                            </div>
                                            <div className="crd_bdy">
                                                <p className="card-text">Soyez accessible en moins de 2 secondes par vos clients</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="icard col-md-6">
                                    <div className="card c4">
                                        <div className="d-flex flex-row align-items-center h-100 crd_cnt"  >
                                            <div className="p-2">
                                                <div className="card-title"><FontAwesomeIcon icon={faChartBar} className="icon" /></div>
                                            </div>
                                            <div className="crd_bdy">
                                                <p className="card-text">1 seul clic suffit pour connaître votre entreprise</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    };
}