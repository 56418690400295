import React from 'react';
import { BrowserRouter as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

import '../styles/components/button.scss';


export default class Button extends React.Component {
    render() {
        const {
            link = "#contact",
            text = "Nous contacter",
            icon = faAngleRight,
            externalLink = false,
            darkBG = false,
            formButton = false,
            bigButton = false
        } = this.props;

        let target;
        if (externalLink) {
            target = (
                <a href={link} className={darkBG ? "button_main dark_bg" : "button_main"}>
                    {text}&nbsp;&nbsp;&nbsp;<span className="icon">
                        <FontAwesomeIcon icon={icon} />
                    </span>
                </a>
            );
        } else if (formButton) {
            target = (
                <div className={darkBG ? "button_main dark_bg" : "button_main"} >
                    <button type="submit" className="btn">{text}</button>
                </div>
            );
        } else {
            target = (
                <Link exact to={link}>
                    <div className={darkBG ? "button_main dark_bg" : "button_main"}>
                        {text}&nbsp;&nbsp;&nbsp;
                        <span className="icon">
                            <FontAwesomeIcon icon={icon} />
                        </span>
                    </div>
                </Link>
            );
        }
        return (
            <div className={bigButton ? "button gs-btn" : "button"}>
                {target}
            </div>
        );
    }
}