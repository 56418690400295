import React from 'react';
import { Helmet } from 'react-helmet';

import Button from '../../../components/Button';
import ArticleCover from './sections/ArticleCover';
import BlogSection from '../../../components/sections/BlogSection';

import '../../../styles/pages/Blog/singleArticle.scss';

export default class Article extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            article: null
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/blog/article/${this.props.match.params.slug}`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        article: result
                    });
                },
                (error) => {
                    this.setState({
                        error: error
                    });
                }
            )
    }

    render() {
        const { article } = this.state;
        
        return article && (
            <div className="single-article">
                <Helmet>
                    <title>{article.metaTitle ? article.metaTitle : article.name ? article.name : ""}</title>
                    <meta name="description" content={article.metaDesc ? article.metaDesc : article.excerpt ? article.excerpt.substring(0, 155) : article.content ? new DOMParser().parseFromString(article.content, 'text/html').querySelector('p').textContent.substring(0, 155) : ""} />
                </Helmet>
                <ArticleCover title={article.name} publishDate={article.date} />
                <section id="article_container">
                    <div className="container">
                        <div className="row d-flex flex-column justify-content-center">
                            <div className="article_content mb-5" dangerouslySetInnerHTML={{ __html: article.content }} />
                            <div className="d-flex flex-row flex-wrap align-items-center justify-content-center mb-5">
                                <Button link="/contact" externalLink />
                            </div>

                            {/* Autor */}
                            <div className="author d-flex flex-column align-items-center">
                                <img className="rounded-circle mb-2" src={process.env.REACT_APP_BACKEND_URL + article.publisherAvatar.file} alt={article.publisher} height="100" />
                                <span>
                                    <span className="article_datatype">Auteur :&nbsp;</span>
                                    <span className="article_datavalue">{article.publisher}</span>
                                </span>
                            </div>
                            {/* End Author */}
                        </div>
                    </div>
                </section>

                <BlogSection />
            </div>

        );
    }
}
