import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import Layout from './layouts/Layout.jsx';
import Homepage from './pages/Homepage/Homepage.jsx';
import Article from './pages/Blog/SingleArticle/Article.jsx';
import ServicesPage from './pages/Services/ServicesPage/ServicesPage.jsx';
import SingleService from './pages/Services/SingleService/SingleService.jsx';
import Contact from './pages/Contact/Contact.jsx';
import BlogPage from './pages/Blog/BlogPage/BlogPage.jsx';
import ProjectsPage from './pages/Projects/ProjectsPage/ProjectsPage';
import SingleProject from './pages/Projects/SingleProject/SingleProject';
import ScrollToTop from './components/ScrollToTop';
import NotFound from './components/NotFound';

import './App.scss';

function App() {
  library.add(fas)

  return (
    <Layout>
      <main>
        <Router>
          <ScrollToTop />
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/blog" component={BlogPage} />
            <Route exact path="/blog/articles/:slug" component={Article} />
            <Route exact path="/services" component={ServicesPage} />
            <Route exact path="/services/:slug" component={SingleService} />
            <Route exact path="/projects" component={ProjectsPage} />
            <Route exact path="/projects/:slug" component={SingleProject} />
            <Route exact path="/contact" component={Contact} />
            <Route component={NotFound} />
          </Switch>
        </Router>
      </main>
    </Layout>
  );
}

export default App;
