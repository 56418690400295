import React from 'react';
import { Helmet } from 'react-helmet';
import CoverColorSection from '../../../components/sections/CoverColorSection';
import BlogNav from './sections/BlogNavSection';

export default class BlogPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            isLoaded: false,
            categories: [],
            currentCategory: null
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/blog/categories`)
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        isLoaded: true,
                        categories: result,
                        currentCategory: result[0] ? result[0] : null
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>Actualité web &amp; tech - Suivez les news - Lotaky</title>
                    <meta name="description" content="Lotaky : Restez informé des dernières actualités. Nous vous partageons toute notre veille ainsi que les dernières news de l'agence." />
                </Helmet>
                <CoverColorSection title="Actu Lotaky" height='extra-tall' categories={this.state.categories} />
                <BlogNav error={this.state.error} isLoaded={true} categories={this.state.categories} />
            </div>
        );
    }
}