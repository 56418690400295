import React from 'react';
import { NavLink } from 'react-router-dom';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../styles/components/sections/coverSection.scss';

export default class CoverImageSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            windowWidth: window.innerWidth
        }
    }

    render() {
        const {
            bgImage,
            title,
            breadcrumbOrigin = "Accueil",
            breadcrumbPath = "/",
        } = this.props;

        return (
            <section id="cover-image-section">
                <div className="overlay" style={{
                    background: 'linear-gradient(0deg, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.6) 100%), url(' + bgImage + ') center center no-repeat',
                    backgroundSize: 'cover'
                }} />
                <div className="content row justify-content-start align-items-center h-100 d-none d-sm-flex">
                    <div className="col-sm-2 h-100 d-flex align-items-center">
                        <div className="socials d-flex flex-row justify-content-start align-items-center">
                            <div className="social-icons d-flex flex-column">
                                <a href="tel:+33699062551"><FontAwesomeIcon icon={faPhone} className="rotate" /></a>
                                <a href={"mailto:" + process.env.REACT_APP_CONTACT_EMAIL}><FontAwesomeIcon icon={faEnvelope} /></a>
                                <a href="https://www.linkedin.com/company/lotaky" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
                                <a href="https://www.instagram.com/lotaky.fr/"><FontAwesomeIcon icon={faInstagram} /></a>
                            </div>
                            <div className="social-text h-100">
                                <div className="d-flex flex-column align-items-center justify-content-between h-100">
                                    <span>suivez-nous</span>
                                    <div className="line" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-10 d-flex justify-content-start align-items-center">
                        <div className="title d-flex flex-column justify-content-center">
                            <h1 className={title.split(' ').map(word => {
                                if (word.length > 10) {
                                    return " breakWord ";
                                }
                                return null;
                            }) + " d-none d-sm-flex"}>{title}</h1>
                            <span className="wrapper_nav">
                                <span>
                                    <NavLink exact to={breadcrumbPath}>{breadcrumbOrigin}</NavLink>
                                </span>&nbsp;&nbsp;/&nbsp;&nbsp;
                                <span>{title}</span>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="content justify-content-between flex-column h-100 d-flex d-sm-none">
                    <div className="col-sm-12 title">
                        <h1 className={title.split(' ').map(word => {
                            if (word.length > 10) {
                                return " breakWord ";
                            }
                            return null;
                        }) + " d-flex d-sm-none"}>{title}</h1>
                    </div>
                    <div className="socials col-sm-12 row">
                        <div className="social-icons col-xs-1 d-flex flex-column">
                            <a href="tel:+33699062551"><FontAwesomeIcon icon={faPhone} className="rotate" /></a>
                            <a href={"mailto:" + process.env.REACT_APP_CONTACT_EMAIL}><FontAwesomeIcon icon={faEnvelope} /></a>
                            <a href="https://www.linkedin.com/company/lotaky" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
                            <a href="https://www.instagram.com/lotaky.fr/"><FontAwesomeIcon icon={faInstagram} /></a>
                        </div>
                        <div className="social-text col-xs-1">
                            <div className="d-flex flex-column align-items-center justify-content-between h-100">
                                <span>suivez-nous</span>
                                <div className="line" />
                            </div>
                        </div>
                    </div>
                    <div className="title col-sm-12">
                        <span className="wrapper_nav">
                            <span>
                                <NavLink exact to={breadcrumbPath}>{breadcrumbOrigin}</NavLink>
                            </span>&nbsp;&nbsp;/&nbsp;&nbsp;
                            <span>{title}</span>
                        </span>
                    </div>
                </div>
            </section >
        );
    }
}