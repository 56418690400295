import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import Slider from 'react-slick';
import ArticleCard from '../../../../components/ArticleCard';
import CategoryDots from '../../../../components/CategoryDots';
import BlogMobileCategorySections from './BlogMobileCategorySection';

import '../../../../styles/pages/Blog/blogNavSection.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function debounce(fn, ms) {
    let timer
    return _ => {
        clearTimeout(timer)
        timer = setTimeout(_ => {
            timer = null
            fn.apply(this, arguments)
        }, ms)
    };
}

function BlogNav(props) {

    const [currentCategory, setCurrentCategory] = useState(null);
    const [buttonsActive, setButtonsActive] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const slider = useRef(null);

    useEffect(() => {
        if (currentCategory == null && props.categories.length) {
            setCurrentCategory(props.categories[0]);
        }
    }, [currentCategory, props.categories]);

    useEffect(() => {
        const debounceHandleResize = debounce(function handleResize() {
            setWindowWidth(window.innerWidth);
        }, 1000);

        window.addEventListener('resize', debounceHandleResize);

        return _ => {
            window.removeEventListener('resize', debounceHandleResize);
        }
    });

    useEffect(() => {
        if (currentCategory) {
            if (windowWidth <= 1200 && currentCategory.articles.length > 2 * 3) {
                setButtonsActive(true);
            } else if (windowWidth > 1200 && windowWidth < 1650 && currentCategory.articles.length > 2 * 4) {
                setButtonsActive(true);
            } else if (windowWidth >= 1650 && currentCategory.articles.length > 2 * 5) {
                setButtonsActive(true);
            } else {
                setButtonsActive(false);
            }
        }
    }, [windowWidth, currentCategory]);

    const _next = () => {
        slider.current.slickNext();
    }

    const _previous = () => {
        slider.current.slickPrev();
    }

    const switchCategory = (category) => {
        setCurrentCategory(category);
    }

    const sliderSettings = currentCategory && {
        infinite: buttonsActive,
        slidesToShow: 5,
        rows: currentCategory.articles.length >= 5 ? 2 : 1,
        slidesToScroll: 5,
        autoplaySpeed: 5000,
        autoplay: true,
        arrows: false,
        draggable: true,
        easing: 'ease',
        responsive: [
            {
                breakpoint: 1650,
                settings: {
                    slidesToShow: 4,
                    rows: currentCategory.articles.length >= 4 ? 2 : 1,
                    slidesToScroll: 4,
                    centerPadding: "0px",
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    rows: currentCategory.articles.length >= 3 ? 2 : 1,
                    slidesToScroll: 3,
                    centerPadding: "0px",
                },
            },
        ],
    };

    return (
        <section id="blog-nav">
            <div className="content d-flex flex-column justify-content-start">
                {!currentCategory || !currentCategory.articles.length ? (
                    <div className="col-lg-12 bl_full_ln text-center">
                        Aucune actu pour le moment.
                    </div>
                ) : (
                    <div>
                        <div className="d-none d-lg-flex flex-column">
                            <div className="article-nav">
                                <div id="nav_arrows">
                                    <button type="button" role="presentation" className="prev" onClick={() => _previous()}>
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </button>
                                    <button type="button" role="presentation" className="next" onClick={() => _next()}>
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </button>
                                </div>
                                <h2 className="ctg-title">{currentCategory.name}</h2>
                            </div>
                            <div className="articles">
                                <Slider ref={c => (slider.current = c)} {...sliderSettings}>
                                    {currentCategory.articles.map(article => (
                                        <ArticleCard key={article.id} article={article} />
                                    ))}
                                </Slider>
                            </div>
                        </div>
                        <div className="d-flex d-lg-none flex-column">
                            {props.categories.map(category => (
                                <BlogMobileCategorySections key={category.id} category={category} />
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="ctg-nav d-none d-lg-flex flex-column justify-content-start">
                <CategoryDots categories={props.categories} callback={(category) => switchCategory(category)} />
                <div className="cta"><a href="/contact">Nous contacter &gt;</a></div>
            </div>
        </section>
    );
}

export default BlogNav;