import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import { faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../styles/components/sections/coverSection.scss';

class CoverColorSection extends React.Component {
    render() {
        const {
            bgColor = "#21D4FD",
            darkBG,
            title,
            breadcrumbOrigin = "Accueil",
            breadcrumbPath = "/",
            height,
            major,
            noBreak = false,
            categories,
        } = this.props;

        return (
            <section
                id="cover-color-section"
                style={{ backgroundColor: bgColor }}
                className={(darkBG ? "dark-bg" : "") + " row m-0 " + height}
            >
                <div className="col-md-9 col-9 h-100 p-0">
                    <div className="content part1 row d-flex flex-row align-items-start m-0 w-100">
                        <div className="row justify-content-start align-items-center h-100 d-none d-sm-flex w-100">
                            <div className="col-2 h-100 d-flex align-items-start">
                                <div className="socials d-flex flex-row justify-content-start align-items-center">
                                    <div className="social-icons d-flex flex-column">
                                        <a href="tel:+33699062551"><FontAwesomeIcon icon={faPhone} className="rotate" /></a>
                                        <a href={"mailto:" + process.env.REACT_APP_CONTACT_EMAIL}><FontAwesomeIcon icon={faEnvelope} /></a>
                                        <a href="https://www.linkedin.com/company/lotaky" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
                                        <a href="https://www.instagram.com/lotaky.fr/"><FontAwesomeIcon icon={faInstagram} /></a>
                                    </div>
                                    <div className="social-text h-100">
                                        <div className="d-flex flex-column align-items-center justify-content-between h-100">
                                            <span>suivez-nous</span>
                                            <div className="line" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-10 h-100 w-100 d-flex justify-content-start align-items-start">
                                <div className={(noBreak ? "noBreak" : "") + " title d-flex flex-column justify-content-center w-100"} >
                                    <h1>{title}</h1>
                                    <span className="wrapper_nav">
                                        <span>
                                            <NavLink exact to={breadcrumbPath}>{breadcrumbOrigin}</NavLink>
                                        </span>&nbsp;&nbsp;/&nbsp;&nbsp;
                                        <span>{title}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="content row justify-content-between flex-column h-100 d-flex d-sm-none">
                            <div className="col-sm-12 title">
                                <h1>{title}</h1>
                            </div>
                            <div className="socials col-sm-12 row">
                                <div className="social-icons col-xs-1 d-flex flex-column">
                                    <a href="tel:+33699062551"><FontAwesomeIcon icon={faPhone} className="rotate" /></a>
                                    <a href={"mailto:" + process.env.REACT_APP_CONTACT_EMAIL}><FontAwesomeIcon icon={faEnvelope} /></a>
                                    <a href="https://www.linkedin.com/company/lotaky" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
                                    <a href="https://www.instagram.com/lotaky.fr/"><FontAwesomeIcon icon={faInstagram} /></a>
                                </div>
                                <div className="social-text col-xs-1">
                                    <div className="d-flex flex-column align-items-center justify-content-between h-100">
                                        <span>suivez-nous</span>
                                        <div className="line" />
                                    </div>
                                </div>
                            </div>
                            <div className="title col-sm-12">
                                <span className="wrapper_nav">
                                    <span>
                                        <NavLink exact to={breadcrumbPath}>{breadcrumbOrigin}</NavLink>
                                    </span>&nbsp;&nbsp;/&nbsp;&nbsp;
                                    <span>{title}</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3 col-3 h-100 p-0">
                    <div className="major h-100 d-flex justify-content-center align-items-center">
                        <div className="d-none d-sm-flex">
                            {major}
                        </div>
                        <div className="d-flex d-sm-none flex-column p-2">
                            {categories ? (categories.map(category =>
                                <Link key={category.id} smooth to={this.props.location.pathname + "#" + category.id} className="mobile-nav-category">{category.name}</Link>
                            )) : null}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withRouter(CoverColorSection);